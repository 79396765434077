import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { TokenAnimations } from './Animations/qaar-animations';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from './api.service';
import { OneSignalService } from './one-signal.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: TokenAnimations
})
export class AppComponent implements OnInit {

    loading = false;
    token;
    platform: boolean;
    
    constructor(public translate: TranslateService, @Inject(PLATFORM_ID) private platformId: Object, private router: Router, public checkinService: ApiService, private oneSignal: OneSignalService) {
        this.token = JSON.parse(localStorage.getItem('userToken'));
        this.platform = isPlatformBrowser(platformId) ? true : false;
    }

    ngOnInit() {
        if(this.platform) {
            console.log("Platform Browser oneSingal Init")
            this.oneSignal.onInit();
        }
            this.router.events.subscribe((evt) => {
                if (!(evt instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
        if (this.token) {
            this.getUser(this.token);
        }
    }

    getUser(token) {
        this.checkinService.verifyUserToken(token.userAuth).subscribe((res: any) => {
            if (res.success) {
                this.checkinService.User.next(token);
            }
        }, err => {
        });
    }

}
