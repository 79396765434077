import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentCancelPopupComponent } from '../appointment-cancel-popup/appointment-cancel-popup.component';
import { ApiService } from '../../api.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCALE_ID } from '@angular/core';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-appointment-and-token-status',
  templateUrl: './appointment-and-token-status.component.html',
  styleUrls: ['./appointment-and-token-status.component.scss']
})

export class AppointmentAndTokenStatusComponent implements OnInit {
  tokenDetails
  delay
  expBeginTime
  refreshMe
  expired
  intervalRunnig
  notFound
  appointmentId
  data
  businessNeatURL = environment.businessNeatURL;
  landingPageUrl = environment.landingPageUrl;
  locale
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private toast: ToastrService,
    @Inject(LOCALE_ID) locale: string
  ) { this.locale = locale }

  ngOnDestroy() {
    if (this.intervalRunnig) {
      clearInterval(this.intervalRunnig);
    }
  }

  ngOnInit(): void {
    this.appointmentId = this.route.snapshot.queryParamMap.get('i');
    if (this.appointmentId) {
      this.apiService.appointmentStatusTokenDetails(this.appointmentId).subscribe((res) => {
        this.data = res
        this.tokenDetails = res.tokenDetails
        let selectedTime, ampm
        this.expBeginTime = res.tokenDetails.expBeginTime
        this.countExpireTime()
        this.checkTokenStatus()
        const formatAMPM = (hours, minutes) => {
          ampm = hours >= 12 ? 'PM' : 'AM';
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? '0' + minutes : minutes;
          selectedTime = hours + ':' + minutes;
        }
        let b = new Date(this.data.appointMilli);
        let c = b.toLocaleString(this.locale, { timeZone: this.data?.clientDetails?.timeZone })
        let d = new Date(c);
        formatAMPM(d.getHours(), d.getMinutes());
        this.data['date'] = d
        this.data['time'] = selectedTime + " " + ampm
      }, err => { this.notFound = true })
    }
    this.intervalRunnig = setInterval(() => {
      if (!this.notFound) {
        this.apiService.shortLightTokenStatus({ tokenInstId: this.tokenDetails.tokenInstId }).subscribe((res) => {
          this.notFound = res?.notFound;
          this.expired = res?.expired
          this.refreshMe = false;
          this.expBeginTime = res?.expBeginTime
          if (!this.notFound && !this.expired) {
            this.tokenDetails = res
            this.countExpireTime()
          }
        }, () => { this.refreshMe = true; })
      }
    }, 25000);
  }

  checkTokenStatus() {
    this.apiService.shortTokenStatus({ tokenInstId: this.tokenDetails.tokenInstId }).subscribe((res) => {
      this.notFound = res?.notFound;
      this.expired = res?.expired
      this.refreshMe = false;
      if (!this.notFound && !this.expired) {
        this.tokenDetails = res
      }
    }, () => { this.refreshMe = true; });
  }

  countExpireTime() {
    this.delay = Math.round((this.expBeginTime - this.data.appointMilli) / 60000)
    if (this.delay > 5) {
      return `Delayed ${this.delay} mins`
    }
    else {
      return "On Time"
    }
  }

  close() {
    this.router.navigateByUrl(`portal/${this.data.clientDetails.shortCode}`)
  }

  cancelToken() {
    this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      disableClose: true,
      panelClass: 'no-pad-mat-dialog',
      data: {}
    }).afterClosed().subscribe(result => {
      if (result) {
        this.apiService.shortCancelToken({ tokenInstId: this.tokenDetails.tokenInstId }).subscribe(() => {
          this.toast.success('Token Cancelled Successfully!');
          this.tokenDetails = null;
          this.checkTokenStatus()
        })
      }
    })
  }
  openQrCodePopup(pop) {
    this.dialog.open(pop)
  }
  public onGetEvent(event) {
    window.location.href = event
  }
  getStatusColor() {
    if (this.delay > 5) {
      return 'red'
    }
    else {
      return 'green'
    }
  }
}
