import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login.component';
import {IntlInputPhoneModule} from 'intl-input-phone';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgOtpInputModule} from 'ng-otp-input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
    declarations: [LoginComponent],
    imports: [
        CommonModule,
        IntlInputPhoneModule,
        MatProgressSpinnerModule,
        NgOtpInputModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    entryComponents: [LoginComponent]
})
export class LoginModule {
}
