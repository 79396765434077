import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    lang = [];
    selectedLangkey = 'en';

    selectedLang = {
        name: 'English',
        key: 'en'
    };

    constructor(public translate: TranslateService, public checkinService: ApiService) {

        this.getLangList();
        translate.addLangs(['en']);
        translate.setDefaultLang('en');
    }

    onChangeLanguage(lang) {
        this.selectedLangkey = lang;
        this.getLangData(lang);
        this.setLanguageToLocalStorage(lang);
        this.setLanguageToServer(lang);
        this.filterSelectedLang();
    }

    getLangData(code) {
        this.checkinService.getLangData(code).subscribe((res) => {

            this.translate.setTranslation(code, res);
            this.translate.setDefaultLang(code);
        });
    }

    setLanguageToLocalStorage(lng) {
        let local = JSON.parse(localStorage.getItem('userToken'));
        if (local) {
            local.lang = lng;
            localStorage.setItem('userToken', JSON.stringify(local));
        }
    }

    setLanguageToServer(key) {
        if (this.checkinService.isAuthenticated()) {
            this.checkinService.editProfile({userLang: key}).subscribe((res) => {
            });
        }
    }

    getLangList() {
        this.checkinService.getLang().subscribe((res) => {
            this.lang = res;
            this.selectedLang = this.lang.filter((i) => i.key === this.selectedLangkey)[0];
            let token = JSON.parse(localStorage.getItem('userToken'));

            this.onChangeLanguage(token && token.lang ? token.lang : 'en');
        });
    }

    filterSelectedLang() {
        this.selectedLang = this.lang.filter((i) => i.key === this.selectedLangkey)[0];
    }
}
