import { Component, Input, OnInit, PLATFORM_ID, Inject, ViewChild, HostListener, ElementRef } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';

import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ApiService } from '../api.service';
import { LanguageService } from '../language.service';
import { LoginComponent } from '../pages/login/login.component';
import * as moment from 'moment-timezone';

declare var $;

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    
    @ViewChild('navBar', { read: ElementRef, static: false }) navBar: ElementRef;
    clientId: string;
    @HostListener('window:scroll', ['$event'])
    onScroll(event) {
        let scroll = event.srcElement.scrollingElement.scrollTop;
        if (scroll > 10) {
            this.navBar.nativeElement.classList.add('floatingNav')
        } else {
            this.navBar.nativeElement.classList.remove('floatingNav')
        }
    }
    totalTokensAppointments = 0;
    @Input() logo: string;
    displayNewMenu: boolean = false;
    @Input() serviceClientInfo;
    // @Input() hoursList = [];
    services: any[]  = [];
    hoursList: any[] = [];



    isLoggedIn = false;
    userName;
    landingPage = environment.landingPageUrl;
    platform: boolean;

    constructor(public translate: TranslateService, @Inject(PLATFORM_ID) private platformId: Object, private matDialog: MatDialog, private api: ApiService, private router: Router, public langService: LanguageService) {
        this.platform = isPlatformBrowser(platformId) ? true : false;
    }

    ngOnInit(): void {

        const otClient = localStorage.getItem('otClient');
        if (otClient) {
          const clientData = JSON.parse(otClient);
          this.clientId = clientData.details.clientId;
        }

        this.api.user.subscribe((res) => {
            this.isLoggedIn = !!(res && res.userAuth);
            if (res) {
                this.getActiveToken(res.userAuth);
            }
            this.userName = res && res.name ? res.name : null;
        });
        if (this.platform) {
            $(document).ready(() => {
                $(document).click((event) => {
                    const clickover = $(event.target);
                    const _opened = $('.collapse').hasClass('collapse show');
                    if (_opened === true && !clickover.hasClass('navbar-toggler')) {
                        $('button.navbar-toggler').click();
                    }
                });
            });
        }
        if (this.router.url.includes('/t?') || this.router.url.includes('/a?') || this.router.url.includes('/at?') || this.router.url.includes('/portal')) {
            this.displayNewMenu = true;
        }
    }

    
    getClientServices() {
        this.api.getClientService(this.clientId).subscribe((res: any) => {
            this.services = res.services;
            this.services = res.services.filter((service) => {
                return service.active;
            });
            console.log("the services are " , this.services)
        });
    }

    getHours() {
        this.api.getHours(this.clientId).subscribe((res: any) => {
            res.hours.map((i) => {
                i.startTime = i.openOn.hour + ':' + i.openOn.minutes;
                i.closeTime = i.closeOn.hour + ':' + i.closeOn.minutes;

                i.startTime = moment(i.startTime, 'hh:mm').format('hh:mm A');
                i.closeTime = moment(i.closeTime, 'hh:mm').format('hh:mm A');
            });
            this.hoursList = res.hours;
        });
    }

    getDEsc(clientId) {
        this.api.getWebDesc(clientId).subscribe((res) => {
            console.log(res);
        });
    }

    openLoginPopup() {
        this.matDialog.open(LoginComponent, { width: '700px', panelClass: 'no-pad-mat-dialog' });
    }

    getActiveToken(userAuth) {
        this.api.getActiveTokens(userAuth).subscribe((re) => {
            if (re) {
                this.totalTokensAppointments = 0;
                this.totalTokensAppointments = this.totalTokensAppointments + 1;
            }
        });
    }

    logout() {
        this.api.User.next(null);
        localStorage.removeItem('userToken');
        this.router.navigate(['/']);
    }

    onChangeLanguage(lng: any) {
        this.langService.onChangeLanguage(lng.key);
    }

    reload() {
        location.reload();
    }

    openDialogforCancel(popup) {
        this.matDialog.open(popup, { width: '500px' });
    }
    
    navigateUrl() {
        const regex = /[?&]business=([^&]+)/;
        const url = this.router.url
        const match = url.match(regex);
        const businessValue = match ? match[1] : null;
        const shortCode = localStorage.getItem('short-code') || businessValue;
        if (this.router.url.startsWith('/t?') || this.router.url.startsWith('/a?') || this.router.url.startsWith('/at?')) {
            this.router.navigateByUrl(`portal/${shortCode}`)
        }
    }
}
