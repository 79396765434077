import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import {
    ConfigurationOptions,
    ContentOptionsEnum,
    OutputOptionsEnum,
    SortOrderEnum
} from 'intl-input-phone';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TokenAnimations } from '../../Animations/qaar-animations';
import { ApiService } from '../../api.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../language.service';
import { environment } from '../../../environments/environment';

declare var $: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: TokenAnimations
})
export class LoginComponent implements OnInit {

    landingPageURL = environment.landingPageUrl
    submitted = false;

    config = {
        allowNumbersOnly: true,
        length: 5,
        disableAutoFocus: false,
        inputClass: 'otp-inputss'
    };

    configOption3: ConfigurationOptions;
    numberModel;
    iso_code;
    IsRequired;
    isOtpStep = false;
    otpScreen = false;
    apiLoad = false;
    method = 'password';
    loginForm: FormGroup;
    emailOTPForm: FormGroup;

    registerForm: FormGroup;
    Promotions = true;
    otpcodeVerify = false;
    interval;
    timeLeft = 60;
    public resendCode = false;

    isEmail = false;
    isOtpEmail = false;
    numberObj = {
        phoneNumber: '',
        countryPhoneCode: '',
        formattedNumber: ''

    };

    otpCode = '';
    passReq;
    IsSignup = false;
    rsubmited = false;
    platform: boolean;

    constructor(public checkinService: ApiService, @Inject(PLATFORM_ID) private platformId: Object, private dialog: MatDialog, private router: Router, private _formBuilder: FormBuilder, private toast: ToastrService, private translate: TranslateService, public langService: LanguageService) {
        this.platform = isPlatformBrowser(platformId) ? true : false;

        this.configOption3 = new ConfigurationOptions();
        this.configOption3.SelectorClass = 'OptionType3';
        this.configOption3.OptionTextTypes = [];
        this.configOption3.OptionTextTypes.push(ContentOptionsEnum.Flag);
        this.configOption3.OptionTextTypes.push(ContentOptionsEnum.CountryName);
        this.configOption3.OptionTextTypes.push(ContentOptionsEnum.CountryPhoneCode);
        this.configOption3.OutputFormat = OutputOptionsEnum.Number;
        this.configOption3.SortBy = SortOrderEnum.CountryPhoneCode;

        this.loginForm = this._formBuilder.group({
            password: ['', [Validators.required]],
        });

        this.registerForm = this._formBuilder.group({
            firstName: [null, Validators.required],
            lastName: [null],
            email: ['', Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]
        });

        this.emailOTPForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]]
        });

    }

    ngOnInit(): void {


        this.focus();
    }

    focus() {
        setTimeout(() => {
            if (this.platform) {
                let ins = $('.CountryNumberInput');
                ins[0].focus();
            }
        }, 500);
    }

    requiredFlagChange(event) {
        this.IsRequired = event;
    }

    sendotp(resend?) {
        this.apiLoad = true;
        this.isOtpStep = false;

        let req;
        if (this.isOtpEmail) {
            req = {
                phoneOrEmail: this.emailOTPForm.controls.email.value,
                countryPhoneCode: ''
            };
        } else {
            req = {
                phoneOrEmail: this.numberObj.phoneNumber,
                countryPhoneCode: this.numberObj.countryPhoneCode
            };
        }

        this.checkinService.sendOtpRequest(req).subscribe((res: any) => {
            this.config.length = res.length;
            this.otpScreen = true;
            this.apiLoad = false;
            if (resend) {
                this.resendCode = !this.resendCode;
                this.timeLeft = 60;
                this.startTimer();
            }
            this.isOtpStep = true;
        }, error => {
            this.apiLoad = false;
        });
    }

    changeCountry() {
        this.IsRequired = false;
        if (this.platform) {
            $('.CountryNumberInput').val('');
        }
    }

    pauseTimer() {
        clearInterval(this.interval);
    }

    startTimer() {
        this.interval = setInterval(() => {
            if (this.timeLeft > 0) {
                this.timeLeft--;
            } else {
                this.resendCode = !this.resendCode;
                this.pauseTimer();
            }
        }, 1000);
    }

    numberchnage(e: any) {
        this.numberObj.formattedNumber = e.Number;
        this.numberObj.phoneNumber = e.Number.match(/\d/g).join('');
        this.numberObj.countryPhoneCode = e.CountryModel.ISOCode;
    }

    onOTPInputChange($event: string) {
        this.otpCode = $event;
        this.pauseTimer();
        this.timeLeft = 60;
        this.resendCode = false;
    }

    verifyOtpCode() {
        this.otpcodeVerify = false;
        this.apiLoad = true;
        this.checkinService.validateOtpRequest({
            phoneOrEmail: this.isOtpEmail ? this.emailOTPForm.controls.email.value : this.numberObj.phoneNumber,
            otpValue: this.otpCode,
            sendPromotions: this.Promotions,
        }).subscribe((res: any) => {
            this.apiLoad = false;
            if (res.otpverified) {
                if (res.oAuthToken != null) {
                    this.passReq = {
                        userAuth: res.oAuthToken,
                        userId: res.userName,
                        name: res.fullName,
                        firstName: res.firstName,
                        lastName: res.lastName,
                        phoneNumber: res.phoneNumber,
                        email: res.email,
                        phoneCountryCode: res.phoneCountryCode ? res.phoneCountryCode : '',
                        lang: res.userLang ? res.userLang : 'en',
                        sendPromotions: res.sendPromotions,
                        sendReminders: res.sendReminders
                    };
                    if (this.passReq.firstName) {
                        this.langService.onChangeLanguage(this.passReq.lang);
                        let t = JSON.parse(localStorage.getItem('tempToken'));
                        if (t) {
                            this.checkinService.mergeToken({
                                clientId: t.clientId,
                                tokenInstId: t.tokenInstId,
                            }, res.oAuthToken).subscribe((re) => {
                                console.log(re);
                                localStorage.removeItem('tempToken');
                            });
                        }

                        localStorage.setItem('userToken', JSON.stringify(this.passReq));
                        this.checkinService.User.next(this.passReq);
                        this.dialog.closeAll();
                        this.router.navigate(['/dashboard']);
                    } else {
                        this.IsSignup = true;
                    }
                }
            } else {
                this.otpcodeVerify = true;
            }
        });
    }

    onSignup() {

    }

    changeMethod(method) {
        this.isEmail = false;
        this.isOtpEmail = false;
        this.method = method;
        this.IsRequired = false;
        this.focus();
    }

    back() {
        this.otpScreen = false;
        this.otpcodeVerify = false;
    }

    loginWithPass() {
        this.submitted = true;
        if (this.loginForm.valid) {
            this.apiLoad = true;
            let req = {
                username: this.isEmail ? this.loginForm.controls.email.value : this.numberObj.phoneNumber,
                password: this.loginForm.controls.password.value
            };
            this.checkinService.loginWithPass(req).subscribe((res) => {
                this.getUserDetail(res);
            }, error => {
                this.apiLoad = false;
                this.toast.error(this.translate.instant('login_err'));
            });
        }
    }

    getUserDetail(res) {
        this.checkinService.getUserDetail(res.access_token).subscribe((re: any) => {
            this.apiLoad = false;
            if (re.userType === 'USER') {
                this.dialog.closeAll();
                this.toast.success(this.translate.instant('welcome_back'));
                this.langService.onChangeLanguage(re.userLang);
                const passReq = {
                    userAuth: res.access_token,
                    userId: re.userName,
                    name: re.fullName,
                    firstName: re.firstName,
                    lastName: re.lastName,
                    phoneNumber: re.phoneNumber,
                    phoneCountryCode: res.phoneCountryCode ? res.phoneCountryCode : '',
                    email: re.email,
                    lang: re.userLang,
                    sendPromotions: re.sendPromotions,
                    sendReminders: re.sendReminders
                };

                let t = JSON.parse(localStorage.getItem('tempToken'));
                if (t) {
                    this.checkinService.mergeToken({
                        clientId: t.clientId,
                        tokenInstId: t.tokenInstId,
                    }, res.access_token).subscribe((r) => {
                        console.log(r);
                        localStorage.removeItem('tempToken');
                    });
                }
                localStorage.setItem('userToken', JSON.stringify(passReq));
                this.router.navigate(['/dashboard']);
            } else {
                this.toast.error('invalid login');
            }
        }, error => {
            this.apiLoad = false;
            this.toast.error('invalid login');
        });
    }

    proceedLogin() {
        this.langService.onChangeLanguage(this.passReq.lang);
        localStorage.setItem('userToken', JSON.stringify(this.passReq));
        this.checkinService.User.next(this.passReq);
        this.dialog.closeAll();
        this.router.navigate(['/dashboard']);
    }

    editProfile() {
        this.rsubmited = true;
        if (this.registerForm.valid) {
            this.apiLoad = true;
            this.checkinService.editProfile(this.registerForm.value, this.passReq.userAuth).subscribe((res) => {
                this.passReq.name = this.registerForm.value.firstName + ' ' + this.registerForm.value.lastName;
                this.passReq.firstName = this.registerForm.value.firstName;
                this.passReq.lastName = this.registerForm.value.lastName;
                this.passReq.email = this.registerForm.value.email;
                this.langService.onChangeLanguage(this.passReq.lang);

                localStorage.setItem('userToken', JSON.stringify(this.passReq));
                this.checkinService.User.next(this.passReq);
                this.dialog.closeAll();
                this.router.navigate(['/dashboard']);
            });
        }
    }

    onMethod($event: any) {
        this.isEmail = !this.isEmail;
        if (this.isEmail) {
            this.loginForm.addControl('email', new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]));
        } else {
            this.loginForm.removeControl('email');
        }
    }

    onMethodEmailTOP($event: any) {
        this.isOtpEmail = !this.isOtpEmail;
    }
}
