import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component'
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-token-status',
  templateUrl: './token-status.component.html',
  styleUrls: ['./token-status.component.scss']
})

export class TokenStatusComponent implements OnInit {
  tokenInstId
  tokenDetails
  shortCode
  notFound
  expired
  cancelledToken
  intervalRunnig
  refreshMe
  apiSuccess
  businessNeatURL = environment.businessNeatURL;
  landingPageUrl = environment.landingPageUrl;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private toast: ToastrService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.tokenInstId = this.route.snapshot.queryParamMap.get('i');
    this.checkTokenStatus()
    this.apiService.shortLightTokenStatus({ tokenInstId: this.tokenInstId }).subscribe((res) => {
      this.apiSuccess = true
      this.notFound = res?.notFound;
      this.expired = res?.expired
      this.refreshMe = false;
      if(!this.notFound && !this.expired) {
        this.tokenDetails = { ...res, clientDetails: this.tokenDetails?.clientDetails }
      }
    }, () => { this.refreshMe = true;})
    this.intervalRunnig = setInterval(() => {
      if(!this.notFound) {
        this.apiService.shortLightTokenStatus({ tokenInstId: this.tokenInstId }).subscribe((res) => {
          this.notFound = res?.notFound;
          this.expired = res?.expired
          this.refreshMe = false;
          if(!this.notFound && !this.expired) {
            this.tokenDetails = { ...res, clientDetails: this.tokenDetails?.clientDetails }
          }
        }, () => { this.refreshMe = true;})
      }
    }, 25000);
  }

  checkTokenStatus() {
    this.apiService.shortTokenStatus({ tokenInstId: this.tokenInstId }).subscribe((res) => {
      this.notFound = res?.notFound;
      this.expired = res?.expired
      this.refreshMe = false;
      this.shortCode = res?.clientDetails?.shortCode
      if(!this.notFound && !this.expired) {
        this.tokenDetails = res
      }
    }, () => { this.refreshMe = true; });
  }

  openDialog() {
    this.dialog.open(ConfirmationDialogComponent, {
      width: 'auto',
      disableClose: true,
      panelClass: 'no-pad-mat-dialog',
      data: {}
    }).afterClosed().subscribe(result => {
      if (result) {
        this.apiService.shortCancelToken({ tokenInstId: this.tokenInstId }).subscribe(() => {
          this.toast.success('Token Cancelled Successfully!');
          this.tokenDetails = null;
          this.checkTokenStatus()
        })
      }
    })
  }

  getDirection(location) {
    this.apiService.redirectToGMap(location)
  }

  ngOnDestroy() {
    if (this.intervalRunnig) {
      clearInterval(this.intervalRunnig);
    }
  }

  close() {
      this.router.navigateByUrl(`portal/${this.shortCode}`)
  }
  
  openQrCodePopup(pop) {
    this.dialog.open(pop)
  }
  public onGetEvent(event) {
    window.location.href = event
  }
}
