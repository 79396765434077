import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentCancelPopupComponent } from '../appointment-cancel-popup/appointment-cancel-popup.component';
import { ApiService } from '../../api.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import {LOCALE_ID} from '@angular/core';

@Component({
  selector: 'app-appointment-status',
  templateUrl: './appointment-status.component.html',
  styleUrls: ['./appointment-status.component.scss']
})
export class AppointmentStatusComponent implements OnInit {

  notFound
  appointmentId
  data
  businessNeatURL = environment.businessNeatURL;
  landingPageUrl = environment.landingPageUrl;
  locale
  constructor(
    private dialog: MatDialog, 
    private route: ActivatedRoute, 
    private router: Router, 
    private apiService: ApiService,
    @Inject(LOCALE_ID) locale: string
    ) { this.locale = locale }

  ngOnInit(): void {
    this.appointmentId = this.route.snapshot.queryParamMap.get('i');
    
    if(this.appointmentId) {
      this.apiService.appointmentStatus(this.appointmentId).subscribe((res) => {
        this.data = res
        let selectedTime, ampm
        const formatAMPM = (hours, minutes) => {
          ampm = hours >= 12 ? 'PM' : 'AM';
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? '0' + minutes : minutes;
          selectedTime = hours + ':' + minutes;
        }
        let b  = new Date(this.data.appointMilli);
        let c = b.toLocaleString(this.locale, { timeZone: this.data.clientDetails.timeZone }) 
        let d = new Date(c);
        formatAMPM(d.getHours(), d.getMinutes());
        this.data['date'] = d
        this.data['time'] = selectedTime + " " + ampm
        if (res.tokenGenerated) {
          this.router.navigateByUrl(`at?i=${this.appointmentId}`)
        }
      }, err => { this.notFound = true })
    }
  }

  openDialog() {
    this.dialog.open(AppointmentCancelPopupComponent, {
      width: 'auto',
      disableClose: true,
      panelClass: 'no-pad-mat-dialog',
      data: { ...this.data }
    }).afterClosed().subscribe(result => {
      // if (result) {
      //   this.apiService.shortCancelToken({ tokenInstId: this.tokenInstId }).subscribe(() => {
      //     this.toast.success('Token Cancelled Successfully!');
      //     this.tokenDetails = null;
      //     this.notFound = true;
      //   })
      // }
    })
  }
  close() {
    this.router.navigateByUrl(`portal/${this.data.clientDetails.shortCode}`)
  }
  openQrCodePopup(pop) {
    this.dialog.open(pop)
  }
  public onGetEvent(event) {
    window.location.href = event
  }
}