import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {ApiService} from '../api.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(public apiService: ApiService, public router: Router) {
    }
    canActivate(): boolean {
        if (!this.apiService.isAuthenticated()) {
            this.router.navigate(['/check-in']);
            return false;
        } else {
            return true;
        }

    }

}
