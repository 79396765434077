import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class OneSignalService {
    oneSignalId: string;

    constructor(public checkinService: ApiService) {}

    onInit(): void {
        this.onLoad().then((OneSignal) => {
            OneSignal.init({
                appId: "5b89147d-0360-4ff3-9e3e-8d6e40dbd43c",
            })
            OneSignal.getUserId( (userId) => {
                this.oneSignalId = userId
                console.log("One Signal ID: ", this.oneSignalId);
                // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316    
              });
        });
    }

    async onLoad(): Promise<any> {
        window.OneSignal = window.OneSignal || [];
        return new Promise((resolve) => {
            window.OneSignal.push(function () {
                resolve(window.OneSignal);
            });
        });
    }
}
