import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-appointment-cancel-popup',
  templateUrl: './appointment-cancel-popup.component.html',
  styleUrls: ['./appointment-cancel-popup.component.scss']
})
export class AppointmentCancelPopupComponent implements OnInit {

  appointId
  isMobile
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resize(window.innerWidth);
  }
  constructor(public dialogRef: MatDialogRef<AppointmentCancelPopupComponent>, private router: Router, private apiService: ApiService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.appointId = this.route.snapshot.queryParamMap.get('i');
    this.resize(window.innerWidth);
  }

  deleteAppointment() {
    this.apiService.deleteAppointmentStatus(this.appointId).subscribe(res => {
      this.closeDialog();
      const clientId = this.route.snapshot.queryParamMap.get('clientId');
      const businessId = this.route.snapshot.queryParamMap.get('business');
      const i = this.route.snapshot.queryParamMap.get('i');
      const preQueueAppointId = JSON.parse(localStorage.getItem('preQueueAppointId'))
      if (preQueueAppointId && preQueueAppointId == i) {
        localStorage.removeItem('preQueueAppointId');
        this.router.navigateByUrl(`integrate/p/prequeue/${clientId}`);
      } else {
        clientId ? this.router.navigateByUrl(`integrate/a/${clientId}`) : this.router.navigateByUrl(`portal/${businessId}`);
      }
    })
  }

  closeDialog() {
    this.dialogRef.close(true);
  }

  resize(innerWidth) {
    this.isMobile = innerWidth <= 600;
  }

}
