import {BrowserModule, Meta} from '@angular/platform-browser';
import {NgModule, Provider, Type} from '@angular/core';
import {AppComponent} from './app.component';
import {CommonModule, HashLocationStrategy, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {AgmCoreModule} from '@agm/core';
import {environment} from '../environments/environment';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {RouterModule, Routes} from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {AuthGuard} from './guard/auth.guard';
import {LoginModule} from './pages/login/login.module';
import {LanguageService} from './language.service';
import {TransferHttpCacheModule} from '@nguniversal/common';
import {TokenStatusComponent} from './pages/token-status/token-status.component';
import { AppointmentStatusComponent } from './pages/appointment-status/appointment-status.component';
import {ConfirmationDialogComponent} from '../app/pages/confirmation-dialog/confirmation-dialog.component';
import {AppointmentCancelPopupComponent} from '../app/pages/appointment-cancel-popup/appointment-cancel-popup.component';
import {MatDialogModule} from '@angular/material/dialog';
import {NavbarModule} from './navbar/navbar.module';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { WaitlistModule } from './pages/waitlist/waitlist.module';
import { AppointmentAndTokenStatusComponent } from './pages/appointment-and-token-status/appointment-and-token-status.component';

const appRoutes: Routes = [
    {
        path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),

    },
    {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'portal/:id',
        loadChildren: () => import('./pages/portal/portal.module').then(m => m.PortalModule)
    },
    {
        path: 'integrate/:id',
        loadChildren: () => import('./pages/portal/portal.module').then(m => m.PortalModule)
    },
    {
        path: 'integrate/a/:id',
        loadChildren: () => import('./pages/portal/portal.module').then(m => m.PortalModule)
    },
    {
        path: 'integrate/t/:id',
        loadChildren: () => import('./pages/portal/portal.module').then(m => m.PortalModule)
    },
    {
        path: 'integrate/t/check-in/:id',
        loadChildren: () => import('./pages/portal/portal.module').then(m => m.PortalModule)
    },
    {
        path: 'integrate/a/appointment/:id',
        loadChildren: () => import('./pages/portal/portal.module').then(m => m.PortalModule)
    },
    {
        path: 'integrate/a/appointment/:id/:day/:month/:year',
        loadChildren: () => import('./pages/portal/portal.module').then(m => m.PortalModule)
    },
    {
        path: 'integrate/p/:id',
        loadChildren: () => import('./pages/portal/portal.module').then(m => m.PortalModule)
    },
    {
        path: 'integrate/p/prequeue/:id',
        loadChildren: () => import('./pages/portal/portal.module').then(m => m.PortalModule)
    },
    {
        path: 't',
        component: TokenStatusComponent
    },
    {
        path: 'a',
        component: AppointmentStatusComponent
    },
    {
        path: 'at',
        component: AppointmentAndTokenStatusComponent
    },
    {
        path: 'waitlist',
        loadChildren: () => import('./pages/waitlist/waitlist.module').then(m => m.WaitlistModule)
    }
];


declare module '@angular/core' {
    interface ModuleWithProviders<T = any> {
        ngModule: Type<T>;
        providers?: Provider[];
    }
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        TokenStatusComponent,
        ConfirmationDialogComponent,
        AppointmentStatusComponent,
        AppointmentCancelPopupComponent,
        AppointmentAndTokenStatusComponent
    ],
    imports: [
        LoginModule,
        BrowserAnimationsModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        TransferHttpCacheModule,
        HttpClientModule,
        MatDialogModule,
        RouterModule.forRoot(appRoutes),
        ToastrModule.forRoot({timeOut: 3000, positionClass: 'toast-top-right', preventDuplicates: true}),
        AgmCoreModule.forRoot({
            apiKey: environment.googleApi
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        NavbarModule,
        NgxScannerQrcodeModule
    ],
    providers: [Meta],
    entryComponents: [ConfirmationDialogComponent,AppointmentCancelPopupComponent],
    bootstrap: [AppComponent],
    exports: [CommonModule],
})
export class AppModule {
}



